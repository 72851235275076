import {useCallback, useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, Link} from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import {ADMIN_RIGHTS, API_URL, http, LOCALE} from '../../common';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import UseMessage from '../hooks/useMessage';
import ContractRelation from '../ContractRelations';
import ModalForm from '../UX/ModalForm';
import HandMadeDocumentForm from './handMadeDocument';
import {DeleteOutlined, FileAddOutlined, SelectOutlined} from '@ant-design/icons';
import DocumentButton from './DocumentButton';
import PopConfirmButton from '../UX/PopConfirmButton';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import PromoCodeModal from './PromocodeModal';
import Notes from '../Notes';
import notableTypes from '../../common/notableTypes';
import {BonusesTable} from '../Bonuses/BonusesTable';
import {ChangeWriteoffBounusesModal} from '../Bonuses/ChangeWriteoffBounusesModal';
import {returnStatement} from '@babel/types';
import {ChangeCustomerForm} from './changeCustomerForm';

const {SYSTEM, LISTENERS, COMMON} = LOCALE;

const ListenersAcionsPanel = () => {
  const {id} = useParams();

  const dispatch = useDispatch();
  const documents = useSelector(RegistySelectors.documents);
  const handMadeDocuments = useSelector(RegistySelectors.handMadeDocuments);
  const functions = useSelector(RegistySelectors.availableFunctions);
  const loading = useSelector(RegistySelectors.docsLoading);
  const [documentInfoLoading, setDocumentInfoLoading] = useState(false);
  const contractNumber = useSelector(RegistySelectors.contractNumber);
  const {contracts} = useSelector(RegistySelectors.relatedContracts);
  const customerId = useSelector(RegistySelectors.customerId);
  const listenerFullInfo = useSelector(RegistySelectors.listenerFullInfo);
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const displayBonusesButton = adminInfo.adminRights.includes(ADMIN_RIGHTS.bonusesAccrual.id);

  const [open, setOpen] = useState(false);

  const [changeCustomerOpen, setChangeCustomerOpen] = useState(false);
  const [changeBonusesOpen, setChangeBonusesOpen] = useState(false);

  const {showError, showSuccess} = UseMessage();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(RegistyAPI.documents.getAll(id)).then((res) => {
      dispatch(RegistyAPI.operator.getAllChangedDocuments(res.payload.contractId));
    });
    dispatch(RegistyAPI.documents.getAvailable(id));
  }, []);

  const uploadHandMadeDocument = (documentTypeId) => (data) => {
    if (!data.file) {
      return showError('Вы не выбрали ни одного документа на загрузку');
    }

    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('contractId', id);
    formData.append('isChecked', 1);
    formData.append('documentTypeId', documentTypeId);

    dispatch(RegistyAPI.operator.uploadHandMade(formData))
      .then(() => showSuccess('Файл успешно загружен'))
      .catch((err) => showError(err));
  };

  const updateHandMadeDocument = (documentId) => (isChecked) => {
    dispatch(RegistyAPI.operator.updateHandMade({id: documentId, data: {isChecked}}))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const openChangedDocument = (id) => (e) => {
    e.stopPropagation();
    dispatch(RegistyAPI.operator.getChangedDocument(id)).then((res) => {
      if (res.payload.data.fileExt === 'docx') {
        const link = document.createElement('a');
        link.href = res.payload.data.link;
        link.click();
        link.remove();
      } else {
        window.open(res.payload.data.link);
      }
    });
  };

  const deleteChangedDocument = (id) => (e) => {
    dispatch(RegistyAPI.operator.deleteChangedDocument(id))
      .then(() => showSuccess('Документ успешно удален'))
      .catch((err) => showError(err));
  };

  const updatePrimaryDocs = () => {
    return dispatch(RegistyAPI.documents.calcPrimaryDocs(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const sendSecondaryDocs = () => {
    return dispatch(RegistyAPI.documents.sendSecondaryDocs(id))
      .then(() => showSuccess('Вторичные документы успешно отправлены'))
      .catch((e) => showError(e));
  };

  const calcSecondaryDocs = () => {
    return dispatch(RegistyAPI.documents.calcSecondaryDocs(id))
      .then(() => showSuccess('Вторичные документы успешно сформированы'))
      .catch((e) => showError(e));
  };

  const transferInCurrentDate = () => {
    return dispatch(RegistyAPI.documents.transferInCurrentDate(id))
      .then(() => {
        showSuccess();
        dispatch(RegistyAPI.listeners.fullInformation(id));
      })
      .catch((e) => showError(e));
  };

  const sendToAnotherLK = (values) => {
    dispatch(RegistyAPI.documents.sendToAnorherLK({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
    setOpen(false);
  };

  const sendPrimaryDocs = () => {
    return dispatch(RegistyAPI.documents.sendPrimaryDocs(id))
      .then(() => showSuccess('Первичные документы успешно отправлены'))
      .catch((e) => showError(e));
  };

  const removeSecondaryDocs = () => {
    return dispatch(RegistyAPI.documents.removeSecondaryDocs(id))
      .then(() => showSuccess('Вторичные документы успешно удалены'))
      .catch((e) => showError(e));
  };

  const infoBonuses = () => {
    return dispatch(RegistyAPI.documents.infoBonuses(id))
      .then((res) => {
        const bonuses = res.payload.data;
        const availableBonuses = res.payload?.availableBonusesCount;
        // const writeoffBounses =
        //   [...bonuses].reverse().find((bonuse) => bonuse.abortUsingTime === null && bonuse.amount < 0)?.amount || 0;

        Modal.info({
          title: (
            <Space direction="vertical">
              <Typography>Движение бонусов</Typography>
              <Typography>{`Кол-во доступных бонусов: ${availableBonuses}`}</Typography>
              {/* <Typography>otbgzmarchenko@yandex.ru</Typography> */}
              {/* <Typography>{`Кол-во списанных бонусов: ${writeoffBounses}`}</Typography> */}
            </Space>
          ),
          width: '900px',
          bodyStyle: {maxHeight: '900px'},
          closable: true,
          maskClosable: true,
          content: <BonusesTable bonuses={bonuses.reverse()} tableHeight={'700px'} />,
        });
      })
      .catch((e) => showError(e));
  };

  const getTestingResult = (id) => {
    return dispatch(RegistyAPI.testingResults.getById(id)).then(({payload}) => {
      const url = URL.createObjectURL(payload.data);
      window.open(url);
    });
  };

  const actions = [
    {
      title: LISTENERS.SEND_PRIMARY_INOVICE,
      visible: !functions.reSendPrimary,
      disabled: !functions.reSendPrimary,
      action: () => sendPrimaryDocs(),
      confirmText: 'Вы уверены, что хотите отправить первичную документацию на почту пользователя?',
    },
    {
      title: LISTENERS.SEND_SECONDARY_INOVICE,
      visible: !functions.reSendSecondary,
      disabled: !functions.reSendSecondary,
      action: () => sendSecondaryDocs(),
      confirmText: 'Вы уверены, что хотите отправить вторичную документацию на почту пользователя?',
    },
    {
      title: LISTENERS.REMOVE_SECONDARY_INOVICE,
      visible: !functions.removeSecondary,
      disabled: !functions.removeSecondary,
      action: () => removeSecondaryDocs(),
      confirmText:
        'Удаление вторичной документации приведет к переформированию даты вторички, а также номеров удостоверений. Удалить вторичную документацию?',
    },
    {
      title: LISTENERS.CREATE_SECONDARY_INOVICE,
      visible: !functions.calcSecondary,
      disabled: !functions.calcSecondary,
      action: () => calcSecondaryDocs(),
      confirmText: 'Вы уверены, что хотите сформировать вторичную документацию?',
    },
    {
      title: LISTENERS.TRANSFER_IN_CURRENT_DATE,
      visible: !functions.transferInCurrentDate,
      disabled: !functions.transferInCurrentDate,
      action: () => transferInCurrentDate(id),
      confirmText: 'Договору будет назначен новый номер. Вы уверены, что хотите перенести договор в текущую дату?',
    },
    {
      title: LISTENERS.SEND_TO_ANOTHER_CABINET,
      visible: !functions.transferInOtherLK,
      disabled: !functions.transferInOtherLK,
      isSync: true,
      action: () => setOpen(true),
    },
    {
      title: LISTENERS.SEND_PASSWORDS_TO_EMAIL,
      visible: !functions.sendStudentsPasswords,
      disabled: !functions.sendStudentsPasswords,
      action: () => sendEmailAndPasswords(id),
      confirmText: 'Вы уверены, что хотите разослать логины и пароли слушателей на их почты?',
    },
    {
      title: LISTENERS.TESTING_RESULTS,
      visible: !functions.testResults,
      disabled: !functions.testResults,
      action: () => getTestingResult(id),
    },
    {
      title: LISTENERS.UPDATE_PRIMARY_INOVICE,
      visible: !functions.reCalcPrimary,
      disabled: !functions.reCalcPrimary,
      action: () => updatePrimaryDocs(),
      confirmText: 'Вы уверены, что хотите переформировать первичную документацию?',
    },
    {
      title: LISTENERS.INFO_BONUSES,
      visible: !functions.infoBonuses,
      disabled: !functions.infoBonuses,
      action: () => infoBonuses(id),
    },
    {
      title: LISTENERS.CHANGE_WRITREOFF_BONUSES,
      action: () => setChangeBonusesOpen(true),
      visible: !displayBonusesButton,
      isSync: true,
    },
  ];

  const linkToDocument = (documentTypeId, outType, fileName) => {
    setDocumentInfoLoading(true);
    dispatch(AdminAPI.auth.getTempToken())
      .then(({payload}) => {
        const tempToken = payload.tempToken;
        const url = `${API_URL}user/invoice/${id}/${documentTypeId}/${outType}/${tempToken}`;
        window.open(url, '_blank');
        setDocumentInfoLoading(false);
        // http
        //   .get(`admin/invoice/${id}/${documentTypeId}/${outType}/${payload?.tempToken}`, {responseType: 'blob'})
        //   .then((response) => {
        //     if (outType === 'pdf') {
        //       const url = URL.createObjectURL(response.data);
        //       setDocumentInfoLoading(false);
        //       window.open(url);
        //     } else {
        //       let responsefileName = response.headers['content-disposition'].split(';')[1].trim().split('=')[1];
        //       responsefileName = responsefileName.replace(new RegExp('"', 'g'), '');

        //       setDocumentInfoLoading(false);

        //       const link = document.createElement('a');
        //       link.href = URL.createObjectURL(response.data);
        //       if (outType !== responsefileName.split('.')[1]) {
        //         link.download = `${fileName}.${responsefileName.split('.')[1]}`;
        //       } else {
        //         link.download = `${fileName}.${outType}` || `file.${outType}`;
        //       }
        //       link.click();
        //       link.remove();
        //     }
      })
      .catch(() => {
        setDocumentInfoLoading(false);
        notification.error({message: SYSTEM.DOWNLOAD_FAILED});
      });
  };

  const openDocumentWithoutSign = (documentTypeId) => {
    dispatch(RegistyAPI.documents.openDocumentWithoutSign({id, documentTypeId, outType: 'pdf'}))
      .then((response) => {
        const url = URL.createObjectURL(response.payload);
        window.open(url);
      })
      .catch(() => notification.error({message: SYSTEM.DOWNLOAD_FAILED}));
  };

  const ChangedDocument = ({documentTypeId, fileExtension, name}) => {
    return handMadeDocuments
      .filter((document) => document.documentTypeId === documentTypeId && document.fileExt === fileExtension)
      .map((filteredDocument) => {
        return (
          <Space key={filteredDocument.id}>
            <Typography.Link underline onClick={openChangedDocument(filteredDocument.id)} className="link">
              {name}
            </Typography.Link>
            <Popconfirm title="Удалить документ?" onConfirm={deleteChangedDocument(filteredDocument.id)}>
              <Button size="small" danger icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} />
            </Popconfirm>
            <Tooltip title="Проверен">
              <Switch checked={!!filteredDocument.checkDate} onChange={updateHandMadeDocument(filteredDocument.id)} />
            </Tooltip>
          </Space>
        );
      });
  };

  const applyPromoCode = (data) => {
    data.contractNumber = contractNumber;
    dispatch(RegistyAPI.promocode.apply(data))
      .then(() => showSuccess())
      .catch(() => showError());
  };

  const deleteMintrudContractNumber = (contractId) => {
    dispatch(AdminAPI.documentation.deleteContractMintrud(contractId))
      .then((res) => showSuccess())
      .catch((err) => showError(err));
  };

  const getTestResults = (studentTrainingId) => () => {
    dispatch(RegistyAPI.listeners.testResults({studentTrainingId}))
      .then((response) => {
        const url = URL.createObjectURL(response.payload);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        // link.download = 'Exam-results.pdf';
        link.click();
        link.remove();

        URL.revokeObjectURL(url);
      })
      .catch((err) => showError(err.message));
  };
  const downloadTestResults = (studentTrainingId) => () => {
    dispatch(RegistyAPI.listeners.testResults({studentTrainingId}))
      .then((response) => {
        const url = URL.createObjectURL(response.payload);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = `${contractNumber} результаты тестирования.pdf`;
        // link.click();
        // link.download = 'Exam-results.pdf';
        link.click();
        link.remove();

        URL.revokeObjectURL(url);
      })
      .catch((err) => showError(err.message));
  };

  const sendEmailAndPasswords = () => {
    return dispatch(RegistyAPI.listeners.sendEmailAndPasswords(id))
      .then((res) => {
        showSuccess(res?.payload?.status);
      })
      .catch((err) => showError(err));
  };

  const documentsData = documents.filter((document) => (document.showDOCX || document.showPDF ? document : null));

  const lastWriteoffBonuses = useCallback(() => {
    const bonuses = listenerFullInfo?.bonuses || [];

    return [...bonuses].reverse().find((bonuse) => bonuse.abortUsingTime === null && bonuse.amount < 0);
  }, [listenerFullInfo]);

  const handleSubmitReplaceCustomer = () => {
    setChangeCustomerOpen(false);
    dispatch(RegistyAPI.documents.getAll(id)).then((res) => {
      dispatch(RegistyAPI.operator.getAllChangedDocuments(res.payload.contractId));
    });
    dispatch(RegistyAPI.documents.getAvailable(id));
  };

  const handleShowChangeCustomer = () => {
    if (contracts?.length) {
      showError('У договорка не должно быть свзязанных договоров');
    } else {
      setChangeCustomerOpen(true);
    }
  };

  return (
    <>
      <Space direction="vertical" style={{marginBottom: '16px'}} size="middle">
        <Space size={24}>
          <Link to={`/registry/delivery/${id}`} component={Typography.Link} className="link">
            Информация о доставке
            <SelectOutlined style={{marginLeft: '8px'}} />
          </Link>
          <Link to={`/registry/payment/${id}`} component={Typography.Link} className="link">
            Информация об оплате
            <SelectOutlined style={{marginLeft: '8px'}} />
          </Link>
          <Link to={`/customer/${customerId}`} component={Typography.Link} className="link">
            Информация о заказчике
            <SelectOutlined style={{marginLeft: '8px'}} />
          </Link>
          <Button type="link" onClick={handleShowChangeCustomer}>
            Изменить заказчика <SelectOutlined style={{marginLeft: '8px'}} />
          </Button>
        </Space>
        <Space>
          <ModalForm
            title="Выберите промокод для договора"
            onOk={applyPromoCode}
            formItems={<PromoCodeModal />}
            modalProps={{width: '700px'}}
            formProps={{layout: {span: 0, wrapperCol: 0}}}>
            <Button type="primary">Применить промокод к договору</Button>
          </ModalForm>
          <Modal
            title="Изменение заказчика"
            open={changeCustomerOpen}
            onCancel={() => setChangeCustomerOpen(false)}
            width={800}
            bodyStyle={{paddingBottom: 50}}
            footer={null}>
            <ChangeCustomerForm customerId={customerId} contractId={id} onSubmit={handleSubmitReplaceCustomer} />
          </Modal>
          {adminInfo.isOperator ? (
            <PopConfirmButton
              type="secondary"
              danger
              button="Удалить номер Минтруда во всем договоре"
              title="Вы уверены?"
              okText="Да"
              onClick={() => deleteMintrudContractNumber(id)}
            />
          ) : null}
          <Button onClick={getTestResults(id)}>Результаты тестирования</Button>
          <Button onClick={downloadTestResults(id)}>Скачать pезультаты тестирования</Button>
        </Space>
      </Space>
      <Row gutter={[16, 20]}>
        <Col xxl={{span: 10}} span={12}>
          <List
            size="small"
            split={false}
            dataSource={actions}
            renderItem={(item) =>
              item.visible ? null : (
                <DocumentButton
                  title={item.title}
                  action={item.action}
                  isSync={item?.isSync}
                  disabled={item?.disabled}
                  confirmText={item?.confirmText}
                />
              )
            }
          />
          <Space>
            <Table
              dataSource={documentsData}
              loading={loading || documentInfoLoading}
              pagination={false}
              size="small"
              showHeader={false}
              rowKey="name">
              <Table.Column dataIndex="name" />
              <Table.Column
                dataIndex="showPDF"
                render={(showPDF, record) => {
                  return (
                    showPDF &&
                    (record.pdfManual ? (
                      <ChangedDocument documentTypeId={record.documentTypeId} fileExtension="pdf" name="pdf" />
                    ) : (
                      <>
                        <Typography.Link
                          className="link"
                          underline={record.pdfManual}
                          onClick={() =>
                            linkToDocument(record.documentTypeId, 'pdf', `${record?.name} ${contractNumber}`)
                          }>
                          pdf
                        </Typography.Link>
                        {record.documentTypeId === '63ee9041-1e29-4c6e-9d3d-68b6be362797' ? (
                          <Typography.Link
                            className="link"
                            onClick={() => openDocumentWithoutSign(record.documentTypeId)}
                            style={{marginLeft: '12px'}}>
                            pdf без печати
                          </Typography.Link>
                        ) : null}
                      </>
                    ))
                  );
                }}
              />
              <Table.Column
                dataIndex="showDOCX"
                render={(showDOCX, record) => {
                  return (
                    showDOCX &&
                    (record.docxManual ? (
                      <ChangedDocument documentTypeId={record.documentTypeId} fileExtension="docx" name="docx" />
                    ) : (
                      <Typography.Link
                        onClick={() =>
                          linkToDocument(record.documentTypeId, 'docx', `${record?.name} ${contractNumber}`)
                        }
                        className="link">
                        docx
                      </Typography.Link>
                    ))
                  );
                }}
              />
              <Table.Column
                dataIndex="documentTypeId"
                render={(documentTypeId) => (
                  <ModalForm
                    formItems={<HandMadeDocumentForm />}
                    onOk={uploadHandMadeDocument(documentTypeId)}
                    title="Загрузка измененного документа">
                    <Button type="primary" icon={<FileAddOutlined />} size="small" />
                  </ModalForm>
                )}
                align="center"
              />
            </Table>
          </Space>
        </Col>
        <Col xxl={{span: 10}} span={12}>
          <Typography.Title level={3}>Связанные договора</Typography.Title>
          <ContractRelation />
          <Typography.Title level={3} style={{marginTop: '16px'}}>
            Замечания
          </Typography.Title>
          <Notes notableTypeId={notableTypes.contract} maxHeight={300} />
        </Col>
        <Modal open={open} onOk={form.submit} onCancel={() => setOpen(false)} title={LISTENERS.SEND_TO_ANOTHER_CABINET}>
          <Form form={form} onFinish={sendToAnotherLK}>
            <Form.Item name={'email'} label={COMMON.EMAIL} rules={[{required: true}]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Row>
      <ChangeWriteoffBounusesModal
        writeoffBounses={lastWriteoffBonuses()}
        open={changeBonusesOpen}
        contractId={id}
        close={() => setChangeBonusesOpen(false)}
      />
    </>
  );
};

export default ListenersAcionsPanel;
