import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, Link as RouterLink} from 'react-router-dom';
import {CloseOutlined, DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import dayjs from 'dayjs';
import {
  Col,
  Divider,
  Row,
  Grid,
  Form,
  Input,
  Select,
  Checkbox,
  Modal,
  Space,
  Button,
  List,
  Typography,
  message,
  Tooltip,
} from 'antd';

import FilePreview from './FilePreview';
import CustomForm from '../UX/CustomForm';
import Dragndrop from '../UX/Dragndrop';

import {AdminActions, AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {EDUCATION_LEVELS} from '../../common';

import UseMessage from '../hooks/useMessage';
import PopConfirmButton from '../UX/PopConfirmButton';
import RotateLeft from '../../pages/Diploms/RotateLeft';
import RotateRight from '../../pages/Diploms/RotateRight';

const {Item} = Form;

const DiplomForm = () => {
  const {id, studentId} = useParams();
  const navigate = useNavigate();

  const [visible, setVisible] = useState();
  const [uploadedImages, setUploaded] = useState(null);
  const [diplomaUrl, setDiplomaUrl] = useState('');
  const [rotate, setRotate] = useState(0);

  const dispatch = useDispatch();
  const loading = useSelector(AdminSelectors.loading);
  const diplom = useSelector(AdminSelectors.diplom);

  const {showError, showSuccess} = UseMessage();

  const screen = Grid.useBreakpoint();

  const handleCancel = () => setVisible(false);

  const showModal = () => setVisible(true);

  useEffect(() => {
    if (id && id !== 'false') {
      dispatch(AdminAPI.diploms.getById(id));
    }
    return () => {
      dispatch(AdminActions.clearError());
      dispatch(AdminActions.clearDiplom());
    };
  }, []);

  const fetchDiplomInfo = (id) => {
    dispatch(AdminAPI.diploms.getById(id)).then();
  };

  const updateDiplom = (values) => {
    dispatch(AdminAPI.diploms.update({id, data: values}))
      .then(() => {
        if (id && id !== 'false') {
          navigate(-1);
        }
        showSuccess();
      })
      .catch((e) => showError());
  };

  const uploadFiles = () => {
    const formData = new FormData();

    if (!Array.isArray(uploadedImages)) return showError('Загрузите файлы диплома');
    uploadedImages.map((file) => formData.append('diplomContent[]', file.originFileObj));
    formData.append('studentId', studentId);

    if (!id || id === 'false' || id === false) {
      dispatch(AdminAPI.diploms.create(formData))
        .then((response) => {
          showSuccess();
          setVisible(false);
          setUploaded(null);
          navigate(`/diploma/${response.payload.id}/${studentId}`);
        })
        .catch((e) => showError(e));
    } else {
      dispatch(AdminAPI.diploms.update({id, data: formData}))
        .then(() => {
          showSuccess();
          setUploaded(null);
          setVisible(false);
        })
        .catch((e) => showError(e));
    }
  };

  const onChange = (info) => {
    setVisible(true);

    const isFileLarge = info?.fileList?.find((file) => file.size / 1024 / 1024 > 15);

    if (isFileLarge) return message.error(<h1 style={{display: 'inline-block'}}>Размер файла превышает 15 мб</h1>);

    if (diplom && diplom?.diplomFiles?.length) {
      diplom.diplomFiles.find((file) => {
        if (file.name === info.file.name.split('.')[0]) {
          return message.error(<h1 style={{display: 'inline-block'}}>Файл с таким именем уже существует</h1>);
        } else {
          setUploaded(info.fileList);
        }
      });
    } else {
      return setUploaded(info.fileList);
    }
  };

  const onDelete = (uid) => setUploaded((prev) => prev.filter((image) => image.uid !== uid));

  const goBack = () => navigate(-1);

  const deleteUploadedFile = (fileId) => {
    dispatch(AdminAPI.diploms.deleteFile(fileId))
      .then(() => {
        showSuccess();
        fetchDiplomInfo(id);
      })
      .catch((e) => showError(e));
  };

  const showDocumentInIframe = (link) => (e) => {
    e.preventDefault();
    setDiplomaUrl(link);
  };

  return (
    <Row>
      <Col span={9} xxl={8}>
        <Col span={24}>
          <Row gutter={[12, 24]}>
            <Col span={24}>
              <Row justify={!screen.xl && 'center'}>
                <Divider orientation="left">Загрузка файлов</Divider>
                <Button type="primary" size="large" onClick={showModal} icon={<UploadOutlined />}>
                  Загрузить
                </Button>
                <Modal onOk={uploadFiles} open={visible} confirmLoading={loading} onCancel={handleCancel}>
                  <Space direction="vertical" style={{width: '100%'}}>
                    <FilePreview uploadedImages={uploadedImages} onDelete={onDelete} />
                    <Dragndrop
                      fileList={uploadedImages}
                      onChange={onChange}
                      draggerProps={{
                        beforeUpload: () => {
                          return false;
                        },
                        multiple: true,
                        showUploadList: false,
                        style: {maxWidth: '500px', maxHeight: '200px', margin: 'auto'},
                        disabled: loading,

                        accept: 'image/*,.pdf',
                      }}
                    />
                  </Space>
                </Modal>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {diplom?.diplomFiles && (
            <Row>
              <Col span={24}>
                <Divider orientation="left">Загруженные файлы</Divider>
                <List
                  style={{maxHeight: '300px', overflowY: 'auto'}}
                  dataSource={diplom?.diplomFiles}
                  bordered
                  rowKey={'id'}
                  loading={loading}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <Link onClick={showDocumentInIframe(item.link)} href={item.link}>
                        {item.name}
                      </Link>
                      {item.studentName ? <Typography.Text>Загрузил {item.studentName}</Typography.Text> : null}
                      {item.operatorName ? <Typography.Text>Загрузил {item.operatorName}</Typography.Text> : null}
                      {item.link ? (
                        <PopConfirmButton
                          danger
                          icon={<DeleteOutlined />}
                          title="Удалить?"
                          onClick={() => deleteUploadedFile(item.id)}
                          style={{flexShrink: 0}}
                        />
                      ) : (
                        <Button disabled>Удалено</Button>
                      )}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          )}
          {diplom?.id && (
            <CustomForm onOk={updateDiplom} onCancel={goBack} initialValues={diplom} loading={loading}>
              <Divider orientation="left">Заполнение формы</Divider>
              <Item rules={[{required: true}]} name="qualificationName" label="Название специальности">
                <Input />
              </Item>
              <Item rules={[{required: true}]} name="educationLevelId" label="Уровень образования">
                <Select>
                  {Object.keys(EDUCATION_LEVELS).map((level) => (
                    <Select.Option value={EDUCATION_LEVELS[level].value} key={EDUCATION_LEVELS[level].value}>
                      {EDUCATION_LEVELS[level].name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
              <Item rules={[{required: true}]} name={'surname'} label="Фамилия">
                <Input />
              </Item>
              <Item rules={[{required: true}]} name="series" label="Серия">
                <Input />
              </Item>
              <Item rules={[{required: true}]} name="number" label="Номер">
                <Input />
              </Item>
              <Item label="Проверен">
                <Space>
                  <Item name="acceptance" valuePropName="checked" noStyle>
                    <Checkbox />
                  </Item>
                  {diplom.acceptedDate && diplom.acceptance ? (
                    <>
                      <Typography.Text>{dayjs(diplom.acceptedDate).format('DD.MM.YYYY')}</Typography.Text>
                      {diplom.whoAcceptedName ? <Typography.Text>({diplom.whoAcceptedName})</Typography.Text> : null}
                    </>
                  ) : null}
                </Space>
              </Item>
            </CustomForm>
          )}
        </Col>
      </Col>
      {diplomaUrl ? (
        <Col span={12} style={{margin: '0px', marginLeft: '40px', position: 'relative'}}>
          <Tooltip title="Закрыть">
            <Button
              style={{position: 'absolute', right: 0, zIndex: 3}}
              type="primary"
              danger
              icon={<CloseOutlined />}
              onClick={() => setDiplomaUrl('')}
            />
          </Tooltip>
          <Button
            type="text"
            style={{position: 'absolute', right: 40, zIndex: 3}}
            icon={<RotateRight />}
            onClick={() => setRotate((cur) => cur + 90)}
          />
          <Button
            type="text"
            style={{position: 'absolute', right: 80, zIndex: 3}}
            icon={<RotateLeft />}
            onClick={() => setRotate((cur) => cur + 90)}
          />
          <iframe
            src={diplomaUrl}
            style={{width: '100%', height: '100%', transform: `rotate(${rotate}deg)`, zIndex: 0}}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default DiplomForm;
