import {createAsyncThunk} from '@reduxjs/toolkit';
import {http} from '../../common';

const path = (str) => `admin/${str}`;

export const AdminAPI = {
  //  Авторизация
  auth: {
    checkAccess: createAsyncThunk('admin/profile', async () => {
      return await http.get('auth/profile');
    }),
    loginAsUser: createAsyncThunk('user/profile', async (email) => {
      return await http.post('auth/login-as-user', {email});
    }),
    getTempToken: createAsyncThunk('admin/getTempToken', async () => {
      return (await http.get('auth/tempToken')).data;
    }),
  },

  // Администраторы
  admins: {
    getAll: createAsyncThunk('admins/getAll', async () => (await http.get('admin/admins')).data),
    signUp: createAsyncThunk('admins/signUp', async (data) => (await http.post('admin/admins', data)).data),
    getAdmin: createAsyncThunk('admins/getById', async (adminId) => (await http.get(`admin/admins/${adminId}`)).data),
    editAdmin: createAsyncThunk('admins/edit', async ({adminId, data}) => {
      return (await http.patch(`admin/admins/${adminId}`, data)).data;
    }),
  },

  // Заказчики
  customers: {
    getAll: createAsyncThunk('registry/customer/getAll', async (data) => {
      return (await http.post(path(`customers`), data)).data;
    }),
    getInfo: createAsyncThunk('registry/customer/getInfo', async (data) => {
      return (await http.get(path(`/customers/${data.id}`))).data;
    }),
    getById: createAsyncThunk('customers/getById', async ({id, ext}) => {
      return (await http.get(path(`customers/${id}/${ext}`))).data;
    }),
    getCountByFilter: createAsyncThunk('customers/getCount', async (filter) => {
      return (await http.post(path(`customers/filter-count`), {filter: filter})).data;
    }),
    update: createAsyncThunk('registry/customer/update', async ({id, data}) => {
      return (await http.patch(path(`customers/${id}`), data)).data;
    }),
    delete: createAsyncThunk('registry/customer/delete', async (id) => {
      return (await http.delete(path(`customers/${id}`))).data;
    }),
    copy: createAsyncThunk('registry/customer/copy', async (data) => {
      return (await http.post(path(`customers/create/`), data)).data;
    }),
    replaceContractCustomer: createAsyncThunk('registry/customer/replaceContractCustomer', async (data) => {
      return (await http.post(path(`contracts/replaceContractCustomer`), data)).data;
    }),
  },

  //  Оператор
  operator: {
    getAll: createAsyncThunk('operators/getAll', async (data) => {
      return (await http.get(path('operators/get-all'), data)).data;
    }),
    availability: createAsyncThunk('operator/availability', async (data) => {
      return (await http.put(path('operator/availability'), data)).data;
    }),
    contracts: createAsyncThunk('operator/contracts', async () => {
      return (await http.get(path('operator/contracts'))).data;
    }),
    getContractById: createAsyncThunk('operator/getContractById', async (data = {id: ''}) => {
      return (await http.get(path(`operator/contracts/${data.id}`))).data;
    }),
    getAnalytics: createAsyncThunk('operator/getAnalytics', async ({data}) => {
      return (await http.post(path(`operators/analytics`), data)).data;
    }),
    editOperator: createAsyncThunk('operator/edit', async ({admin, data}) => {
      return (await http.put(path(`operators/${admin}`), data)).data;
    }),
  },

  // Пользователи
  users: {
    create: createAsyncThunk('users/create', async (data) => (await http.post(`admin/users`, data)).data),
    getById: createAsyncThunk('users/getById', async ({id, ext}) => (await http.get(`admin/users/${id}/${ext}`)).data),
    update: createAsyncThunk('users/update', async ({id, data}) => (await http.put(`admin/users/${id}`, data)).data),
    delete: createAsyncThunk('users/delete', async (data) => (await http.delete(`admin/users/${data.id}`)).data),
    filter: createAsyncThunk('users/filter', async (data) => (await http.put('admin/users/filtered-list', data)).data),
    addBonuses: createAsyncThunk('users/addBonuses', async (data) => (await http.post(`admin/bonus/add`, data)).data),
    changePassword: createAsyncThunk(
      'users/changePassword',
      async ({id, data}) => (await http.post(`admin/users/change-password/${id}`, data)).data
    ),
  },

  listeners: {
    getAll: createAsyncThunk('listeners', async (data) => (await http.put('admin/students/filtered-list', data)).data),
  },

  // Промокоды
  promocodes: {
    getAll: createAsyncThunk('promocodes/getAll', async (data) => {
      return (await http.post(`admin/promocodes/list`, data)).data;
    }),
    create: createAsyncThunk('promocodes/create', async (data) => {
      return (await http.post(path(`promocodes`), data)).data;
    }),
    getById: createAsyncThunk('promocodes/getById', async (id) => {
      return (await http.get(path(`promocodes/${id}`))).data;
    }),
    update: createAsyncThunk('promocodes/update', async ({id, data}) => {
      return (await http.put(path(`promocodes/${id}`), data)).data;
    }),
    delete: createAsyncThunk('promocodes/delete', async (id) => {
      return (await http.delete(path(`promocodes/${id}`))).data;
    }),

    prices: {
      getAll: createAsyncThunk('promocodes/prices/getAll', async (id) => {
        return (await http.get(path(`promocodes/promocodePrices/${id}`))).data;
      }),
      create: createAsyncThunk('promocodes/prices/create', async ({id, data}) => {
        return (await http.post(path(`promocodes/promocodePrices/${id}`), data)).data;
      }),
      delete: createAsyncThunk('promocodes/prices/delete', async (id) => {
        return (await http.delete(path(`promocodes/promocodePrices/${id}`))).data;
      }),
      update: createAsyncThunk('promocodes/prices/update', async ({id, data}) => {
        return (await http.put(path(`promocodes/promocodePrices/${id}`), data)).data;
      }),
    },
  },

  //  Настройки системы
  companySettings: {
    getAll: createAsyncThunk('global-settings/getAll', async () => {
      return (await http.get(path(`global-settings`))).data;
    }),
    update: createAsyncThunk('global-settings/update', async (data) => {
      return (await http.patch(path(`global-settings`), data)).data;
    }),
  },
  //  Настройки компании
  companyInfo: {
    getAll: createAsyncThunk('company-settings/getAll', async () => {
      return (await http.get(path(`company-settings`))).data;
    }),
    getById: createAsyncThunk('company-settings/getById', async (id) => {
      return (await http.get(path(`company-settings/${id}`))).data;
    }),
    create: createAsyncThunk('company-settings/create', async (data) => {
      return (await http.post(path(`company-settings`), data)).data;
    }),
    update: createAsyncThunk('company-settings/update', async ({id, data}) => {
      return (await http.put(path(`company-settings/${id}`), data)).data;
    }),
  },
  //  Приказы
  orders: {
    getAll: createAsyncThunk('orders/getAll', async () => {
      return (await http.get(path(`orders`))).data;
    }),
    getById: createAsyncThunk('orders/getById', async (id) => {
      return (await http.get(path(`orders/${id}`))).data;
    }),
    create: createAsyncThunk('orders/create', async (data) => {
      return (await http.post(path(`orders`), data)).data;
    }),
    update: createAsyncThunk('orders/update', async ({id, data}) => {
      return (await http.patch(path(`orders/${id}`), data)).data;
    }),
    delete: createAsyncThunk('orders/delete', async (id) => {
      return (await http.delete(path(`orders/${id}`))).data;
    }),
  },

  diploms: {
    getById: createAsyncThunk('diplom/getById', async (id) => {
      return (await http.get(path(`diploms/${id}`))).data;
    }),
    create: createAsyncThunk('diplom/create', async (data) => {
      return (await http.post(path(`diploms`), data)).data;
    }),
    update: createAsyncThunk('diplom/update', async ({id, data}) => {
      return (await http.post(path(`diploms/${id}`), data)).data;
    }),
    deleteFile: createAsyncThunk('diplom/delete', async (id) => {
      return (await http.delete(path(`diploms/diplom-files/${id}`))).data;
    }),
  },

  operatorsAnalytics: {
    getOperatorsAnalytics: createAsyncThunk('operators_analytics', async (data) => {
      return await http.post(`admin/operators/analytics`, data);
    }),
  },

  analytic: {
    getAnalytic: createAsyncThunk('analytic', async (data) => {
      return await http.post('admin/analytic', data);
    }),
    getAnalyticXLSX: createAsyncThunk('analyticXLSX', async (data) => {
      return await http.post('admin/analytic/excel', data, {
        responseType: 'blob',
      });
    }),
    getAnalyticRating: createAsyncThunk('analyticRating', async (data) => {
      return await http.post('admin/analytic/rating', data, {
        responseType: 'blob',
      });
    }),
    getAnalyticFull: createAsyncThunk('analyticFull', async (data) => {
      return await http.post('admin/analytic/full', data, {
        responseType: 'blob',
      });
    }),
    getAnalyticBonuses: createAsyncThunk('analyticBonuses', async (data) => {
      return await http.post('admin/analytic/bonuses', data, {
        responseType: 'blob',
      });
    }),
  },

  documents: {
    getDocumentsZIP: createAsyncThunk('documentsZIP', async (id) => {
      return await http.get(`admin/zipDocuments/${id}`, {responseType: 'blob'});
    }),
    getFolderDocuments: createAsyncThunk('folderDocuments', async (contractId) => {
      return await http.get(`admin/folder/${contractId}`);
    }),
  },

  //  Комиссия
  commissionMembers: {
    getAll: createAsyncThunk('commission/getAll', async () => {
      return (await http.get(path(`commission-members`))).data;
    }),
    getById: createAsyncThunk('commission/getById', async (id) => {
      return (await http.get(path(`commission-members/${id}`))).data;
    }),
    create: createAsyncThunk('commission/create', async (data) => {
      return (await http.post(path(`commission-members`), data)).data;
    }),
    update: createAsyncThunk('commission/update', async ({id, data}) => {
      return (await http.put(path(`commission-members/${id}`), data)).data;
    }),
    delete: createAsyncThunk('commission/delete', async (id) => {
      return (await http.delete(path(`commission-members/${id}`))).data;
    }),
    getTypes: createAsyncThunk('commission/types', async (id) => {
      return (await http.get(path(`commission-members/${id}/get-training-program-types`))).data;
    }),
    removeAllTypes: createAsyncThunk('commission/removeAll', async (id) => {
      return (await http.patch(path(`commission-members/${id}/untie-from-all-training-program-types`))).data;
    }),
    removeType: createAsyncThunk('commission/removeOne', async ({id, typeId}) => {
      return (await http.patch(path(`commission-members/${id}/untie-from-training-program-type/${typeId}`))).data;
    }),
    createType: createAsyncThunk('commission/create-type', async ({id, data}) => {
      return (await http.post(path(`commission-members/${id}/add-to-training-program-type`), data)).data;
    }),
  },

  //  Коммерческие предложения
  commercialProposes: {
    getAll: createAsyncThunk('commercial-proposes', async (data) => {
      return (await http.put('admin/commercialOffer', data)).data;
    }),
    getById: createAsyncThunk('commercial-propose', async (id) => {
      return await http.get(`admin/commercialOffer/${id}`);
    }),
    render: createAsyncThunk('commercial-propose/render', async (id) => {
      return await http.get(`public/commercialOffer/${id}`, {responseType: 'blob'});
    }),
  },

  // Система контроля контента
  systemContentControl: {
    getMainPartList: createAsyncThunk('content/mainPart', async () => {
      return await http.get('admin/skk/mainPartList');
    }),
    getAdditionalPartList: createAsyncThunk('content/additionalPart', async (mainPartUuid) => {
      return await http.get(`admin/skk/additionalPartList/${mainPartUuid}`);
    }),
    getNormHistory: createAsyncThunk('content/history', async (normUuid) => {
      return await http.get(`admin/skk/normHistory/${normUuid}`);
    }),
    checkActuality: createAsyncThunk('content/actuality', async (itemId) => {
      return await http.get(`admin/skk/checkActualContentOne/${itemId}`);
    }),
    create: createAsyncThunk('content/create', async (data) => {
      return (await http.post('admin/skk/createContent', data)).data;
    }),
    checkActualityList: createAsyncThunk('content/actualityList', async (data) => {
      return (await http.post('admin/skk/checkActualContentList', data)).data;
    }),
    unlinkNorm: createAsyncThunk('content/unlinkNorm', async (data) => {
      return (await http.post('admin/skk/unlinkNormFromContent', data)).data;
    }),
    addNorm: createAsyncThunk('content/addNorm', async (data) => {
      return (await http.post('admin/skk/addNormsToContent', data)).data;
    }),
    actualize: createAsyncThunk('content/actualize', async (data) => {
      return (await http.post('admin/skk/actualizeContent', data)).data;
    }),
  },

  documentation: {
    postReport: createAsyncThunk('documentation/postReport', async (data) => {
      return (await http.put('admin/registry/postReport', data, {responseType: 'blob'})).data;
    }),
    mintrud: createAsyncThunk('documentation/mintrud', async (data) => {
      return (await http.put('admin/registry/mintrud', data, {responseType: 'blob'})).data;
    }),
    eisot: createAsyncThunk('documentation/eisot', async (data) => {
      return (await http.put('admin/registry/eisot', data, {responseType: 'blob'})).data;
    }),
    mintrudNumbers: createAsyncThunk('documentation/mintrudNumbers', async (data) => {
      return (await http.post('admin/registry/mintrud/upload', data)).data;
    }),
    paymentDocuments: createAsyncThunk('documentation/paymentDocuments', async (data) => {
      return (await http.post('admin/payment-documents', data)).data;
    }),
    deleteContractMintrud: createAsyncThunk('deleteContractMintrud', async (contractId) => {
      return await http.delete(`admin/registry/management/mintrud/${contractId}`);
    }),
    deleteStudentMintrud: createAsyncThunk('deleteStudentMintrud', async (studentId) => {
      return await http.delete(`admin/registry/management/mintrud/student/${studentId}`);
    }),
    deleteStudentTrainingMintrud: createAsyncThunk('deleteStudentTrainingMintrud', async (studentTrainingId) => {
      return await http.delete(`admin/registry/management/mintrud/training/${studentTrainingId}`);
    }),
  },

  // Отчеты ФИС
  reports: {
    pkpp: createAsyncThunk('report/pkpp', async (month) => {
      return (await http.get('admin/fis/pkpp', {params: {month}, responseType: 'blob'})).data;
    }),
    po: createAsyncThunk('report/po', async (data) => {
      return (await http.get('admin/fis/po', {params: data, responseType: 'blob'})).data;
    }),
  },

  customersJobTitle: {
    getAll: createAsyncThunk('customerJobTitle/all', async () => {
      return (await http.get('admin/customers/head-posts')).data;
    }),
    create: createAsyncThunk('customerJobTitle/create', async (data) => {
      return (await http.post('admin/customers/head-posts', data)).data;
    }),
    getOne: createAsyncThunk('customerJobTitle/one', async (id) => {
      return (await http.get(`admin/customers/head-posts/${id}`)).data;
    }),
    edit: createAsyncThunk('customerJobTitle/edit', async ({id, data}) => {
      return (await http.patch(`admin/customers/head-posts/${id}`, data)).data;
    }),
    deleteOne: createAsyncThunk('customerJobTitle/delete', async (id) => {
      return (await http.delete(`admin/customers/head-posts/${id}`)).data;
    }),
  },

  statistic: {
    get: createAsyncThunk('statistic/get', async (data) => {
      return (await http.get('admin/statistics', {params: data, responseType: 'blob'})).data;
    }),
  },

  note: {
    getAll: createAsyncThunk('note/all', async (data) => {
      return (await http.put('admin/notes', data)).data;
    }),
    create: createAsyncThunk('note/create', async (data) => {
      return (await http.post('admin/notes', data)).data;
    }),
    getOne: createAsyncThunk('note/one', async (id) => {
      return (await http.get(`admin/notes/${id}`)).data;
    }),
    edit: createAsyncThunk('note/edit', async ({id, data}) => {
      return (await http.patch(`admin/notes/${id}`, data)).data;
    }),
    deleteOne: createAsyncThunk('note/delete', async (id) => {
      return (await http.delete(`admin/notes/${id}`)).data;
    }),
  },
};
