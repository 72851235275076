import {Checkbox, Space, Spin, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import dayjs from 'dayjs';

export function StudentFields({studentId}) {
  const [loadin, setLoading] = useState(false);
  const [listenerInfo, setListenerInfo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RegistyAPI.listeners.getById(studentId)).then((res) => setListenerInfo(res.payload));
  }, [studentId]);

  function handleChangeIsStudent(event) {
    const checked = event.target.checked;
    setLoading(true);
    dispatch(RegistyAPI.listeners.update({id: studentId, data: {isStudent: checked}}))
      .then((res) => {
        setListenerInfo(res.payload);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  function handleBlankNumber(event) {
    const checked = event.target.checked;
    setLoading(true);
    dispatch(RegistyAPI.listeners.update({id: studentId, data: {hasStudentBlankNumber: checked}}))
      .then((res) => {
        setListenerInfo(res.payload);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  return (
    <Space
      direction="vertical"
      style={{
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
        padding: '10px',
        width: '100%',
        maxWidth: '500px',
        marginTop: '15px',
      }}>
      <Space
        direction="horizontal"
        style={{width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'space-between'}}>
        <Typography.Text style={{width: '200px'}}>Студент</Typography.Text>
        <Space>
          {!listenerInfo || loadin ? (
            <Spin />
          ) : (
            <Checkbox checked={listenerInfo.isStudentDate} onChange={handleChangeIsStudent} disabled={!listenerInfo} />
          )}

          {listenerInfo && listenerInfo?.isStudentDate ? (
            <>
              <Typography.Text>{dayjs(listenerInfo.isStudentDate).format('DD.MM.YYYY')}</Typography.Text>
              {listenerInfo?.whoSetIsStudentId ? (
                <Typography.Text>({listenerInfo.whoSetIsStudentId})</Typography.Text>
              ) : null}
            </>
          ) : null}
        </Space>
      </Space>
      <Space
        direction="horizontal"
        style={{width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'space-between'}}>
        <Typography.Text style={{width: '200px'}}>Студент номер бланка</Typography.Text>
        <Space>
          {!listenerInfo ? (
            <Spin />
          ) : (
            <Checkbox
              checked={listenerInfo?.hasStudentBlankNumberDate}
              onChange={handleBlankNumber}
              disabled={!listenerInfo}
            />
          )}

          {listenerInfo && listenerInfo?.hasStudentBlankNumberDate ? (
            <>
              <Typography.Text>{dayjs(listenerInfo.acceptedDate).format('DD.MM.YYYY')}</Typography.Text>
              {listenerInfo?.whoSetHasStudentBlankNumberId ? (
                <Typography.Text>({listenerInfo?.whoSetHasStudentBlankNumberId})</Typography.Text>
              ) : null}
            </>
          ) : null}
        </Space>
      </Space>
    </Space>
  );
}
